import {trackMatomoFormConversion} from "./utils";

document.addEventListener('DOMContentLoaded', function () {
    const subscribeToNewsletter = (form: HTMLFormElement, locale) => {
        const nameInput: HTMLInputElement | null = form.querySelector('.newsletter_name');
        const mailInput: HTMLInputElement | null = form.querySelector('.newsletter_email');
        if (!nameInput || !mailInput) {
            return;
        }
        const formData = new FormData();
        formData.append('name', nameInput.value);
        formData.append('email', mailInput.value);
        formData.append('lang', locale);
        const action = '/website_newsletter_form/';
        fetch(action, {
            method: 'POST',
            credentials: 'omit',
            headers: {
                'Accept': 'application/json'
            },
            body: formData
        }).then((resp) => {
            return resp.json();
        }).then(() => {
            // console.log(_body);
        }).catch((error) => {
            console.error(error);
        });
    }

    const showError = (errorContainer: HTMLElement | null, msg: string) => {
        if (errorContainer) {
            errorContainer.textContent = msg;
            errorContainer.style.display = 'block'
        }
    }

    const sendForm = (form: HTMLFormElement) => {
        const locale = form.dataset.locale || '';
        const modelName = form.dataset.model || '';
        const default_msg = form.dataset.error || 'The server sent an unknown answer format.';
        const successContainer: HTMLElement | null = form.querySelector('.success-message');
        const errorContainer: HTMLElement | null = form.querySelector('.error-message');
        if (errorContainer) {
            errorContainer.style.display = 'none'
        }
        const formData = new FormData(form);
        const filteredFormData = new FormData();
        const formId = form.id || 'unknown-form';
        const subscribe = formData.get('newsletter') === 'Yes';
        formData.forEach((value, key) => {
            if (!(value === '' || value === undefined || value === null)) {
                filteredFormData.append(key, value);
            }
        });
        const action = `${form.action}${modelName}`;
        form.classList.add('processing');
        fetch(action, {
            method: 'POST',
            credentials: 'omit',
            headers: {
                'Accept': 'application/json'
            },
            body: filteredFormData
        }).then((resp) => {
            if (!resp.ok) {
                if (resp.status === 429) {
                    return Promise.reject('Too many requests, please try again later.');
                }
                return Promise.reject(`${default_msg} Code: ${resp.status}`);
            }
            return resp;
        }).then((resp) => {
            return resp.json();
        }).then((body) => {
            if (body.id || body.ok) {
                if (subscribe) {
                    subscribeToNewsletter(form, locale);
                }
                if (successContainer) {
                    successContainer.style.display = 'flex';
                }
                trackMatomoFormConversion(formId);
            } else {
                console.error(body);
                form.classList.remove('processing');
                showError(errorContainer, body.error || default_msg);
            }
        }).catch((error) => {
            form.classList.remove('processing');
            console.error(error);
            let msg = error?.message || error;
            if (error instanceof SyntaxError) {
                msg = default_msg;
            }
            showError(errorContainer, msg);
        });
    }

    const handleForm = (form: HTMLFormElement) => {
        form.addEventListener('submit', function (event) {
            event.preventDefault();
            event.stopPropagation();
            if (!form.classList.contains('processing') && form.checkValidity()) {
                sendForm(form);
            }
        }, false)
    }

    const forms = document.querySelectorAll('.augur-form');
    Array.prototype.slice.call(forms).forEach(handleForm);
});